<template>
  <div class="shop-target">
    <div v-for="item in list" :key="item" :class="item.status==1||item.status==3?'red':'green'" style="position: relative;">
      <el-descriptions :title="item.name" :extra="item.status==1?'未开始':item.status==2?'考核中':'已结束'" @click="toDetail(item)">
        <el-descriptions-item v-if="false" label="考核时间：">{{item.creatTime}}</el-descriptions-item>
        <el-descriptions-item label="角色：">{{item.role}}</el-descriptions-item>
      </el-descriptions>
      <el-button style="position: absolute;right: 10px;top: 50px;" size="mini" type="danger" @click="remove(item)">删除</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import {deleteCheck} from '@/service/main/target'
export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  emits: ['refresh'],
  setup(props, { emit }) {
    const router = useRouter()
    const toDetail = (item) =>{
      router.push({
        path: '/main/target/updateCategoryTarget',
        query: {
          id:item.id,
          status:item.status
        }
      })
    }
    const remove = (item) =>{
      popup('确认删除当前考核吗?', async() => {
        let res = await deleteCheck(item.id)
        if (res.code==0) {
          ElMessage.success({
            message: '删除成功!',
            type: 'success'
          })
          emit('refresh')
        }else{
          ElMessage.error({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }


    return {
      toDetail,
      remove
    }

  }
})
</script>

<style scoped lang="less">
.shop-target {
  &:deep(.el-descriptions){
    padding: 20px 20px 0px;
    border-bottom: 1px solid #ccc;
    .el-descriptions__body .el-descriptions__table{
      width: 50%;
    }
  }
  .red{
    &:deep(.el-descriptions){
      .el-descriptions__extra{
        color: #666;
      }
    }
  }
  .green{
    &:deep(.el-descriptions){
      .el-descriptions__extra{
        color: #67c23a;
      }
    }
  }
}
</style>









